/* TextField.css */
.text-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  transition: border-color 0.3s ease;
}

.text-field:focus {
  border-bottom: 2px solid green;
}
