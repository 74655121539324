.react-contextmenu {
  background-color: #333; /* Dark grey background */
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.react-contextmenu-item {
  color: #fff; /* White text */
  padding: 8px 16px;
  cursor: pointer;
}

.react-contextmenu-item:hover {
  background-color: #444; /* Slightly lighter grey for hover */
}
