/* UploadDocument.css */

.upload-document-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #2c2f33;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  width: 100%;
}

.content-container {
  margin-top: 100px;
  width: 100%;
}

.dataTableContainer {
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.form-group label {
  font-size: 16px;
  font-weight: 500;
  color: #f3f3f3;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  font-size: 14px;
  padding: 10px;
  border: 1px solid #636363;
  background-color: #23272a;
  color: #f3f3f3;
  border-radius: 4px;
  width: 300px;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ec1946;
  background-color: #23272a;
  padding: 20px;
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  cursor: pointer;
}

.dropzone p {
  font-size: 16px;
  font-weight: 500;
  color: #f3f3f3;
  text-align: center;
}

.message {
  font-size: 14px;
  font-weight: 400;
  color: #28a745;
  margin-bottom: 20px;
}

.delete-group {
  display: flex;
  align-items: center;
}

.delete-group select {
  margin-right: 10px;
}

.export-group {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

button {
  font-size: 14px;
  font-weight: 500;
  background-color: #ec1946;
  color: #ffffff;
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #c63030;
}

button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.search-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
}

.search-group label {
  margin: 0;
}

.save-group {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.delete-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.export-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}


.action-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.delete-group {
  display: flex;
  align-items: center;
  gap:10px;
  margin-bottom: 20px;
}

.export-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .action-row {
    justify-content: center;
  }
}
.logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20%; /* Adjust the percentage value according to your needs */
  max-width: 300px; /* Set a maximum width so it doesn't get too large on bigger screens */
}

.content-container {
  margin-top: 100px; /* Adjust this value to make sure the logo doesn't overlap the content */
}


.dataTableContainer {
  width: 100%!important;
}
.dataTableContainer .dataTable {
  position: static!important;
}
