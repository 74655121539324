.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2c2f33;
  padding: 1rem;
  font-family: 'Roboto', sans-serif;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 2rem; /* Add space between elements */
}

.select-group {
  margin-bottom: 1rem;
  text-align: center;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4rem;
  margin-top: 50px;
}
.landing-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%; /* Limit the width */
  margin: 0 auto; /* Center the content */
}

.content-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.tables-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Style for search box and button */
#search-text {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
}

/* Style for chart container */
#chart-container {
  height: 500px;
  width: 100%;
  margin-bottom: 1rem;
}

/* Override ag-Grid styles */
/* Existing Bootstrap Table Styles */
.table-style {
  background-color: #2c2f33;
  color: #f3f3f3;
}

/* Updated ag-Grid Styles to Match Bootstrap Table */
.ag-theme-alpine {
  height: 500px;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #a9a9a9; /* New background color */
  color: #23272a; /* New text color */
  text-align: center; /* New text alignment */
}

.ag-header-cell {
  background-color: #444; /* Match Bootstrap table header background color */
  color: #f3f3f3; /* Match Bootstrap table header text color */
  border-color: #555;
}

.ag-row {
  background-color: #a9a9a9; /* New background color */
  color: #23272a; /* New text color */
  text-align: center; /* New text alignment */
}
.ag-row.ag-row-even {
  background-color: #a9a9a9; /* New background color */
  color: #23272a; /* New text color */
  text-align: center; /* New text alignment */
}

.ag-row-selected {
  background-color: #a9a9a9; /* New background color */
  color: #23272a; /* New text color */
  text-align: center; /* New text alignment */
}

.ag-cell {
  background-color: #a9a9a9; /* New background color */
  color: #23272a; /* New text color */
  text-align: center; /* New text alignment */
}


/* Context Menu */
#context-menu {
  background-color: #444;
  color: #fff;
  border: 1px solid #555;
}

#context-menu button {
  background-color: #555;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin: 2px;
  cursor: pointer;
}

#context-menu button:hover {
  background-color: #666;
}


.tool-links {
  display: flex;
  justify-content: space-between; /* Add space between the buttons */
  width: 80%; /* Adjust as needed */
}

.tool-link {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  text-decoration: none;
  color: #f3f3f3; /* Change back to white */
  background-color: #ec1946;
  border-radius: 4px;
  transition: background-color 0.2s;
  margin: 0 10px; /* Add horizontal margin */
}

.tool-link:hover:not(.disabled) {
  background-color: #c63030;
}

.tool-link.disabled {
  background-color: #999;
  cursor: not-allowed;
}

.sign-out {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  color: #fff;
  background-color: #ec1946;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.logo {
  width: 600px;
  margin-bottom: 20px;
}

/* Existing CSS */

/* New toolbar styles */
#toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #000; /* Add a border */
}

#toolbar > div {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #ddd;
  cursor: grab;
}

#toolbar > div:active {
  cursor: grabbing;
}

/* Style for the placeholder message */
.placeholder {
  color: #888;
}
.h1-container {
  margin-top: 50px; /* Move the H1 lower on the page */
  color: white; /* Make the text color white */
  font-size: 48px; /* Make the text even larger */
}

.h1-container h1 {
  color: white; /* Override any inherited color */
}