.custom-geocoder {
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  align-items: center;
  background-color: #23272a;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 4px;
}

.custom-geocoder input {
  flex: 1;
  padding: 8px 8px;
  line-height: 1.2;
  border: 1px solid #636363;
  background-color: #23272a;
  color: #f3f3f3;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
}
.custom-geocoder button {
  background-color: #ff3f3f;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.custom-geocoder button:hover {
  background-color: #c63030;
}