#layerMenu .scrollable-section {
  margin-top: 10px;
  white-space: nowrap;
}

#layerMenu .layerchoice {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 3px 8px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

#layerMenu .layerchoice:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#layerMenu .layerchoice > span {
  flex-shrink: 0;
  width: 20px;
  cursor: pointer;
}

#layerMenu .layerchoice > label {
  flex-grow: 1;
}

#layerMenu .layerchoice > input[type="checkbox"] {
  flex-shrink: 0;
  margin-left: 10px;
}
.layerMenuTab {
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  height: 30px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 101;
}


.layerMenuTab:hover {
  background-color: #e0e0e0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.geocoder-container1 {
  position: absolute;
  top: 20px;
  left: 500px;
  transform: translate(-50%, -50%);
  z-index: 1002;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.map-container {
  position: relative;
  width: 100%;
  height: 70vh; /* You can adjust this value to fit your needs */
  overflow: hidden;
}

.data-table {
  width: 100%;
  max-height: 30vh; /* Adjust this value to limit the height of the data table */
  overflow-y: auto; /* Allows scrolling within the data table when content exceeds max-height */
} 

