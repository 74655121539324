.custom-geocoder {
  position: absolute;
  top: 1%; /* Use percentage for relative positioning */
  left: 50%; /* Start at half the width of the screen */
  transform: translateX(-50%); /* Shift to the left by half its own width to center */
  display: flex;
  align-items: center;
  background-color: #23272a;
  border-radius: 4px;
  box-shadow: 0 0.4vh 0.6vh rgba(0, 0, 0, 0.1); /* Use viewport height for shadows */
  padding: 0.4vh; /* Use viewport height for padding */
}

.custom-geocoder input {
  flex: 1;
  padding: 1vh 1vw; /* Use viewport units for padding */
  line-height: 1.2;
  border: 0.1vh solid #636363; /* Use viewport height for border */
  background-color: #23272a;
  color: #f3f3f3;
  border-radius: 4px;
  margin-right: 1vw; /* Use viewport width for margin */
  font-size: 1.4vh; /* Use viewport height for font size */
}

.custom-geocoder button {
  background-color: #ff3f3f;
  color: #ffffff;
  padding: 1vh 1vw; /* Use viewport units for padding */
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 1.4vh; /* Use viewport height for font size */
  font-weight: 500;
  transition: background-color 0.2s;
}

.custom-geocoder button:hover {
  background-color: #c63030;
}

.custom-geocoder__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #23272a;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  box-shadow: 0 0.6vh 1.2vh rgba(0, 0, 0, 0.15); /* Use viewport height for shadows */
  z-index: 1000;
  max-height: 20vh; /* Use viewport height for maximum height */
  overflow-y: auto;
}

.custom-geocoder__suggestions li {
  padding: 1vh 1vw; /* Use viewport units for padding */
  color: #f3f3f3;
  cursor: pointer;
}

.custom-geocoder__suggestions li:hover {
  background-color: #1b1e21;
}
