.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  justify-content: center;
  min-height: 100vh; /* Changed from height to min-height */
  background-color: #2c2f33;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  width: 100%;
  color: #f3f3f3;
}

.page-title {
  align-self: center; /* Center the title */
  font-size: 32px;
  margin-top: 0; /* Remove margin at the top */
  color: #ec1946;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px; /* Make the title smaller */
  font-weight: 400; /* Make the title less bold */
  color: #a9a9a9; /* Change the color to light grey */
}

h1 {
  font-size: 24px;
  font-weight: 500;
  color: #ec1946;
  margin-bottom: 20px;
}

.select-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;

}

.select-group label {
  font-size: 16px;
  font-weight: 400;
  color:#a9a9a9;
  margin-bottom: 5px;
}

.select-group select {
  font-size: 14px;
  padding: 10px;
  border: 1px solid #636363;
  background-color: #23272a;
  color: #a9a9a9;
  border-radius: 4px;
  width: 300px;
}

.table-style {
  width: 100%;
  border: 1px solid #ddd;
}

.table-style th, .table-style td {
  padding: 10px;
  border: 1px solid #ddd;
}

.table-style th {
  background-color: #a9a9a9;
  text-align: center;
  color: #23272a; /* Changed to dark color for contrast */
}

.table-style td {
  color: #a9a9a9;
}


.table-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20%; /* Adjust the percentage value according to your needs */
  max-width: 300px; /* Set a maximum width so it doesn't get too large on bigger screens */
}
html, body {
  margin: 0;
  padding: 0;
}
.content-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chart-container {
  width: 100%; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd; /* Add border */
  margin-bottom: 20px; /* Add margin */
}

.pie-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}


.tables-container {
  width: 58%; /* Adjust as needed */
}