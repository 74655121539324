


.wedge-input-modal {
  width: 500px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}

.wedge-input-modal input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.wedge-input-modal button {
  padding: 5px 10px;
  background-color: #007bff;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.wedge-input-modal button:hover {
  background-color: #0056b3;
}



.wedge-input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Toggle Buttons Container */
.toggle-buttons {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* Toggle Item */
.toggle-item {
  display: flex;
  align-items: center;
  flex-direction: row-reverse; /* Reverse the order */
  justify-content: flex-end; /* Align items to the end */
  margin-bottom: 10px;
}

/* Toggle Item Label */
.toggle-item label {
  margin-left: 10px; /* Adjust margin to the left instead of right */
}

.admin-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.geocoder-container {
  position: absolute;
  top: 20px;
  left: 600px;
  z-index: 1000;
}
.access-layer-container {
  display: flex;
  flex-direction: column;
   height: 100vh;
}

.map-container {
  position: relative;
  width: 100%;
  height: 50vh; /* You can adjust this value to fit your needs */
  
}

.data-table-container {
  width: 100%;
  margin-top: 20px; /* Add some space between the map and the data table */
}
.toolbar1 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;
  z-index: 100;
}
.toggle-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.toggle-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.toggle-item label {
  margin-left: 10px;
}
.wedge-input-modal.compact {
  width: auto; /* Auto width */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wedge-input-modal.compact input,
.wedge-input-modal.compact select {
  width: 70%;
  padding: 8px;
  margin: 4px 0;
}

.wedge-input-modal.compact button {
  padding: 4px 8px;
  font-size: 14px;
}
.button-group {
  margin-top: 20px;
}

.button-group button {
  margin-right: 10px; /* Add some margin to the right of each button */
}

.button-group button:last-child {
  margin-right: 0; /* Remove the margin from the last button */
}


.advanced-link {
  margin-top: 10px;
  font-size: 12px; /* Smaller text */
}
.wedge-input-modal input,
.wedge-input-modal select {
  width: 75%;  /* Reduced to 75% */
  padding: 7.5px;  /* Reduced to 75% */
  margin: 3.75px 0;  /* Reduced to 75% */
}

.wedge-input.advanced {
  flex-direction: row; /* Change to column when advanced settings are shown */
}

.advanced-settings {
  width: 100%; /* Take full width */
  display: flex;
   /* Allow wrapping */
}

.input-group {
  flex: row;
  margin: 2px; /* Add some margin */
}







.small-input {
  width: 75%;  /* Reduced to 75% */
}
.wedge-input button {
  margin-bottom: 10px;
}





.small-input,
.priority-select {
  width: 100%;
  font-size: 12px; /* Smaller text */
}


.priority-section label {
  margin-right: 10px;
}

.settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.priority-select {
  padding: 12px; /* Increase padding */
  font-size: 16px; /* Increase font size */
}
.modal-content {
  position: relative; /* For the close button */
}
.close-button {
  position: absolute;
  top: 0;  /* Adjust as needed */
  right: 0;  /* Adjust as needed */
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;  /* Ensure it's above other elements */
  padding: 10px;  /* Add some padding so it's not flush against the corner */
}
.initial-settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.small-label {
  font-size: 12px; /* Adjust the font size as needed */
  margin-bottom: 5px; /* Adjust the margin as needed */
  display: inline-block; /* Keeps the label inline with the input */
}
.dropdown-container {
 display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.uniform-button {
  width: 120px;  /* Fixed width */
  height: 40px;/* Fixed height */
  font-size: 12px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.toggle-item-draw {
  position: relative; /* This makes it a positioning context for absolute children */
}

.toggle-item-draw button,
.toggle-item-draw select {
  position: absolute;
  bottom: 10px;
  z-index: 1000;
}

.toggle-item-draw button {
  left: 10px;
}

.toggle-item-draw select {
  left: calc(10px + 150px); /* Adjust the 100px as needed */
}
#toolbar {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  min-height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.placeholder {
  color: #999;
}

.ag-header-cell-label {
  transition: color 0.3s ease;
}

.ag-header-cell:hover .ag-header-cell-label {
  color: black; /* Change text color on hover */
}
.ag-theme-alpine.ag-popup {
  background-color: transparent; /* Remove any background color */
  opacity: 1; /* Set full opacity */
}

.save-plan-button {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 1000; /* Ensure it's above the map layers */
  background-color: #007bff; /* Your button color */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.save-plan-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
.uniform-button.loading {
  background-color: grey;
  cursor: not-allowed;
}
