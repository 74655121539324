* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  background-color: #2c2f33; /* Add this line */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layerchoice {
  display: flex;
  width: 100px;
  justify-content: space-between;
  animation: mymove 5s infinite;
  
  
  
}

#layerMenu {
  position: "absolute";
  top:0;
  left:0;
  zIndex: 100;
  background-color: rgba(255,255,255,0.7);
  padding: 10px;
  animation: mymove 5s infinite;
}

.SearchBar {
  display: flex;
  width: 100px;
  top:20px;
  justify-content: space-between;
  
  
  
}


#SearchMenu {
  position: 'absolute';
  top:20px;
  left:0;
  zIndex: 100;
  background-color: rgba(255,255,255,0.7);
  padding: 10px;
}

.Propchoice {
  display: flex;
  width: 100px;
  justify-content: space-between;
  
  
  
}

#PropMenu {
  position: "absolute";
  top:0;
  left:0;
  zIndex: 100;
  background-color: rgba(0, 255, 199, 1);
  padding: 10px;
  
}

#Menu {
  position: "absolute";
  top:0;
  left:0;
  zIndex: 100;
  background-color: rgba(255,255,255,0.7);
  padding: 10px;
  animation: mymove 5s infinite;
}

#container li {
  margin-bottom: 10px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  z-index: 2;
}
html, body {
  margin: 0;
  padding: 0;
}
