

.MuiDrawer-root {
  position: fixed; 
  top: 10%; /* Adjust top margin */
  right: 0; 
  height: 80%; /* Set height to 80% of the viewport */
  z-index: 1300; 
}
.session-list {
  width: 250px;
}

.show-session-button {
  margin-top: 8px;
  margin-left: 20px;
  margin-bottom: 8px;
}

/* Add more styles for modern and sleek look */
.MuiButton-root {
  text-transform: none; /* Remove uppercase transform */
  margin: 4px 0; /* Add some spacing around buttons */
}

/* Style the list items */
.MuiListItem-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

/* Style the text in list items */
.MuiListItemText-primary {
  font-size: 0.875rem; /* Adjust font size if needed */
  color: #333; /* Set a darker font color for contrast */
}
.session-timestamp {
  margin-left: 20px;
  font-size: 0.75rem; /* Smaller font size for the timestamp */
  color: #666; /* Lighter font color for the timestamp */
}
.session-list-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.button-group {
  display: flex;
  justify-content: space-between; /* Space out buttons */
  width: 100%; /* Full width of the container */
  padding: 8px; /* Adjust padding as needed */
}

.show-session-button, .add-session-button, .unsave-plan-button {
  flex-grow: 1; /* Each button takes equal space */
  margin: 0 4px; /* Adjust margin for spacing between buttons */
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px; /* Optional: add border radius for rounded corners */
}
.session-list .button-group .MuiButton-root {
  flex-grow: 1;
  margin: 0 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
